import React from "react"

import "./product.scss"

const ProductPage = props => {
  return (
    <>
      <div className="product-banner"></div>

      <div className="product-content">
        {/* Heading */}
        <div className="product-heading">
          <div className="product-heading-text">{props.productTitle}</div>
          <div className="product-heading-lead-lines">
            <div className="product-heading-lead-line-top"></div>
            <div className="product-heading-lead-line-bottom"></div>
          </div>
        </div>
        {/* Sub-Heading */}
        {/* <div className="product-subheading">
          <b>this is a subheading</b>
        </div> */}

        {/* Product Showcase */}
        <div className="product-showcase">
          <ProductShowcase images={props.showcaseImages} />
          <div className="product-showcase-desc">
            <div className="product-showcase-desc-heading">
              {props.productTitle}
            </div>
            <div className="product-showcase-desc-content">
              {props.showcase}
            </div>
          </div>
        </div>

        {/* Description */}
        <div className="product-description-heading">Description</div>
        <div className="product-description-heading-underline"></div>
        <div className="product-description-content">{props.description}</div>

        {/* Technical Details */}
        {props.technicalDetails && (
          <>
            <div className="product-technical-heading">Technical Details</div>
            <div className="product-technical-content">
              {props.technicalDetails}
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default ProductPage

class ProductShowcase extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      images: this.props.images,
      focusImageIndex: 0,
    }
  }

  componentDidMount() {
    this.interval = setInterval(
      () => this.changeImg(this.state.focusImageIndex + 1),
      3000
    )
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  changeImg = index => {
    clearInterval(this.interval)
    if (index == this.state.images.length) {
      index = 0
    }

    this.setState({
      focusImageIndex: index,
    })
    this.interval = setInterval(
      () => this.changeImg(this.state.focusImageIndex + 1),
      3000
    )
  }

  render() {
    return (
      <>
        <div className="product-showcase-imgs">
          <div className="product-showcase-img-focus">
            <img
              src={this.state.images[this.state.focusImageIndex]}
              alt="product image"
            />
          </div>
          <div className="product-showcase-img-bar">
            {this.state.images.map((image, i) => {
              if (i == this.state.focusImageIndex) {
                return (
                  <button key={i}>
                    <img
                      className="product-showcase-img-bar-focus"
                      src={image}
                      alt="product image"
                    />
                  </button>
                )
              } else {
                return (
                  <button
                    key={i}
                    onClick={() => {
                      this.changeImg(i)
                    }}
                  >
                    <img src={image} alt="product image" />
                  </button>
                )
              }
            })}
          </div>
        </div>
      </>
    )
  }
}
