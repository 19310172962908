import React from "react"
import { Link } from "gatsby"
import SEO from "../../components/layout/seo"

import Layout from "../../components/layout/layout"
import ProductPage from "../../components/product/product"

import img1 from "../../images/ac-dc-emergency-bulb/123.jpg"
// import img2 from "../../images/ac-dc-emergency-bulb/124.jpg"
// import img3 from "../../images/ac-dc-emergency-bulb/125.jpg"
import img4 from "../../images/ac-dc-emergency-bulb/126.jpg"
import img5 from "../../images/ac-dc-emergency-bulb/new1.jpeg"
import img6 from "../../images/ac-dc-emergency-bulb/new2.jpeg"
import img7 from "../../images/ac-dc-emergency-bulb/new3.jpeg"

import emergencyBulbSpecSheet from "../../images/ac-dc-emergency-bulb/specSheet.svg"
import emergencyTubeSpecSheet from "../../images/ac-dc-emergency-bulb/specSheet2.svg"

const ACDCEmergencyBulbPage = () => {
  return (
    <Layout home={false}>
      <SEO title="Industrial Emergency Bulb and Tube Light" />
      <ProductPage
        productTitle="Industrial Emergency Bulb and Tube Light"
        showcase={
          <>
            <p style={{ lineHeight: "1.7rem" }}>
              <b>AC/DC Emergency Light Bulb</b>
            </p>
            <ul>
              <li>Power Consumption: 9 W</li>
              <li>10 hrs Charging Time, 4 hrs Back-up Time</li>
              <li>Battery Capacity: 2200 mAh</li>
              <li>Power Source: Rechargeable</li>
            </ul>
            <p style={{ lineHeight: "1.7rem" }}>
              <b>Emergency Tube Light</b>
            </p>
            <ul>
              <li>Power Consumption: 20 W</li>
              <li>3 hrs Back-up Time</li>
              <li>Battery Capacity: 2400 mAh</li>
              <li>Power Source: Rechargeable</li>
            </ul>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Link
                  to="/contact-us"
                  style={{
                    backgroundColor: "#203864",
                    marginTop: "1rem",
                    color: "white",
                    textDecoration: "none",
                    padding: "0.5rem 1rem",
                    fontFamily: "Helvetica",
                  }}
                >
                  Enquire Now
                </Link>
              </div>
            </div>
          </>
        }
        showcaseImages={[img1, img4, img7,img5, img6]}
        description={
          <>
            <div style={{ width: "80%", lineHeight: "1.5rem" }}>
              <p style={{ fontSize: "1.2rem" }}>
                <b>High street LED Emergency Light Bulb</b> is a highly
                efficient backup system for power cuts and emergency situations.
                HSL has a retro B22 base which can be used as a regular bulb to
                light up your space as well.
              </p>
              <p style={{ fontSize: "1.2rem" }}>
                HSL emergency bulb is packed with a powerful Lithium-ion battery
                2600 mAh battery. The Maximum Charging Time 08-10 Hours.
              </p>
            </div>
            <div style={{ width: "80%", lineHeight: "1.5rem" }}>
              <p style={{ fontSize: "1.2rem" }}>
                <b>HSL LED Industrial Tube-Light</b> can be used as a regular tube
                light to light up your space. Packed with a 12.8V6AH LFP Battery
                for longer backup during power cut, this tube immediately lights
                up in case of an electricity outage.
              </p>
              <p style={{ fontSize: "1.2rem" }}>
                HSL emergency tube light has enhanced features to cater to all
                your needs during a power cut. Maximum Charging Time 05-08Hours
              </p>
            </div>
            <div className="product-description-heading">Application</div>
            <div className="product-description-heading-underline"></div>
            <div style={{ width: "80%", lineHeight: "1.5rem" }}>
              <p style={{ fontSize: "1.2rem" }}>
                Application: High street Inverter bulb is the perfect lighting
                backup during power cuts and can be used in your study/drawing
                room and bathroom in your home, retail shops, hospital and
                shops.
              </p>
            </div>
          </>
        }
        technicalDetails={
          <div
            style={{
              width: "180%",
              marginLeft: "-40%",
            }}
          >
            <div
              className="product-description-heading"
              style={{
                fontSize: "2rem",
                margin: "auto",
                textAlign: "center",
                color: "black",
              }}
            >
              AC/DC Emergency Bulb
            </div>
            <div style={{ height: "10px" }}></div>

            <img src={emergencyBulbSpecSheet} style={{ width: "100%" }} />

            <div style={{ height: "100px" }}></div>

            <div
              className="product-description-heading"
              style={{
                fontSize: "2rem",
                margin: "auto",
                textAlign: "center",
                color: "black",
              }}
            >
              Industrial Emergency Tube-Light
            </div>
            <div style={{ height: "10px" }}></div>
            <img src={emergencyTubeSpecSheet} style={{ width: "100%" }} />
          </div>
        }
      />
    </Layout>
  )
}

export default ACDCEmergencyBulbPage
